<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Komisi </h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pt-3">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                    
                    <CCol class="sm-3">
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol>
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">Total Komisi Terbayar</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{TotalPaidCommission}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <CTabs>
                            <CTab title="Kontak" id="tab-1" active>
                                <commission-contact-report-grid ref="commissionContactReportGrid" 
                                    :key="gridReload" 
                                    :dateTo="DateToChangest" 
                                    :dateFrom="DateFromChangest"
                                />
                            </CTab>
                            
                            <CTab title="Project" id="tab-2" >
                                <commission-project-report-grid ref="commissionProjectReportGrid" 
                                    :key="gridReload" 
                                    :dateTo="DateToChangest" 
                                    :dateFrom="DateFromChangest"
                                />
                            </CTab>
                        </CTabs>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'
import { report, datetime_zone } from '../../../infrastructure/constant/variable';

import CommissionContactReportGrid from '../CommissionReport/Grid/CommissionContactReportGrid.vue';
import CommissionProjectReportGrid from '../CommissionReport/Grid/CommissionProjectReportGrid.vue';
import CommissionReportService from '../CommissionReport/Script/CommissionReportService.js';

export default {
    name: 'CommissionReport',
    components: {
        'commission-contact-report-grid': CommissionContactReportGrid,
        'commission-project-report-grid': CommissionProjectReportGrid,
        'date-picker': datePicker
    },   
    data(){
        return{
            gridReload: 0,
            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,
            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            TotalPaidCommission: '',

            Error: 0,
            
            DateTypeData: [],
            DateType: report.default_date,
        }
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();

        var variables = {
            startDate: this.DateFrom + datetime_zone.date_from,
            endDate: this.DateTo + datetime_zone.date_to,
        };
        this.getQuantity(variables)
    },
    methods: {
        async getQuantity(variables){
            var dataCommissionReport = await CommissionReportService.getCommissionSummary(variables);

            var summaryTotal = dataCommissionReport.summary.total_paid_commission;
            this.TotalPaidCommission = kendo.format('{0:N0}', summaryTotal);
        },
        async onChangeStatusPayment(){
            if(this.StatusPayment == '') {
                this.StatusPayment = null;
            }
            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;

            var variables = {
                startDate: this.DateFrom + datetime_zone.date_from,
                endDate: this.DateTo + datetime_zone.date_to,
            };
            await this.getQuantity(variables);
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables);
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                var variables = {
                    startDate: this.DateFrom + datetime_zone.date_from,
                    endDate: this.DateTo + datetime_zone.date_to,
                };
                await this.getQuantity(variables)
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.commissionContactReportGrid.exportExcel();
        },
    }
}
</script>

<style scoped>
</style>