<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="BookingOrderReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            v-on:detailinit="detailInit"
        >
        </kendo-grid>
    </div>
</template>
  
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import BookingOrderReportServices from '../Script/BookingOrderReportService.js';
  
export default {
    name: 'BookingOrderReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateTo', 'dateFrom'],
    computed: {
        BookingOrderReport () {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                            };
                            return {
                                query: BookingOrderReportServices.readBookingReportQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                data: function (response) {
                        if(response.data.GetReportingBookingOrder == null) {
                            return [];
                        }
                        else {
                            return response.data.GetReportingBookingOrder;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetReportingBookingOrder == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetReportingBookingOrder.length;
                        }
                    },
                    model: {
                        fields: {
                            booking_order_created_date: {type: "date"},
                            purchase_order_date: {type: "date"},
                            quantity: {type: "number" },
                            remaining: {type: "number" },
                            quantity_purchase_order: {type: "number" },
                        }
                    }
                },
                group: [
                    {field: "contact_name"}
                ]
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "booking_order_created_date", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(booking_order_created_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "contact_name", title: "Customer", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "purchase_order_number", title: "No. PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "purchase_order_date", title: "Tanggal PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"},
                    template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },
                { field: "project_name", title: "Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "uom", title: "Satuan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} },
                { field: "quantity", title: "Total Booking", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "remaining", title: "Sisa", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "quantity_purchase_order", title: "Qty PO", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
    },
    methods: {
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "BookingOrderReportGridDetail",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.reporting_booking_order_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'sequence', title: "No", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                    { field: 'purchase_order_number', title: "No. PO", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'purchase_order_date', title: "Tanggal PO", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true,
                        attributes: { "class": "k-text-center" },
                        template: "#= kendo.toString(kendo.parseDate(purchase_order_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                        filterable : {
                            ui: function (element) {
                                element.kendoDatePicker({
                                    format: "yyyy-MM-dd"
                                });
                            }
                        } 
                    },
                    // { field: 'product_code', title: "Kode Item", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'item_name', title: "Nama Item", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true },
                    { field: 'quantity', title: "Qty PO", width: 20, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, editable: false, nullable: true, attributes: { "class": "k-text-right" } },
                ],
            })
        },
        async exportExcel(DateFrom, DateTo) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await BookingOrderReportServices.bookingReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_BookingOrder_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = BookingOrderReportServices.bookingReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>